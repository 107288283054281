@import "../../styles/functions";
@import "../../styles/variables";

.loginform {
    background-color: $white;
    display: flex;
    flex-direction: column;
    font-family: $primaryFont;
    padding: calcRem(30px) calcRem(50px);
    border-radius: calcRem(30px);
    &__line {
        width: calcRem(60px);
        height: calcRem(3px);
        background-color: $black;
        margin-bottom: calcRem(30px);
    }
    &__button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &__forgot {
            color: $foregroundColor;
            font-size: calcRem(14px);
        }
    }
    &__create {
        display: flex;
        justify-content: center;
        padding-top: calcRem(15px) ;
    }
}

canvas{
    background-color: transparent;
}
