@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";


@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.please-scan-container {
  background-color: #e4efea;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.logo-image {
  max-width: 35%; /* Adjust the size as needed */

  @media (max-width: 1000px) {
    max-width: 90%;
  }
}

.scan-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 350px; /* Adjust the maximum width as needed */

  @media (max-width: 768px) {
    width: 80%;
    padding: 0 20px;
  }
}

.first-line {
  display: block;
  font-size: 45px; /* Adjust the font size as needed */
  font-weight: bold;
  margin-bottom: 10px; /* Adjust the spacing between lines as needed */
  margin-top: 1%; /* Adjust the margin to move the line up */

  @media (max-width: 1000px) {
    margin-top: 10%;
    font-size: 30px;
  }
}

.second-line {
  display: block;
  font-size: 25px; /* Adjust the font size as needed */

  @media (max-width: 1000px) {
    font-size: 18px;
  }
}


