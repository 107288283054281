@import "../../styles/functions";
@import "../../styles/variables";


.accordion {

    &#action-header button.accordion-button {
        color: $secondaryTextColor;
        font-family: $primaryFont;
        font-weight: $medium;
        padding: calcRem(22px);
        background: $foregroundColor;
        transition: color 0.13s ease-in;
        -webkit-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
        -moz-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
    }

    &#action-header button.accordion-button:hover{
        background: $actionColor;
        color: $foregroundColor;
        transition: color linear 0.13s;
    }

    &#action-header button.accordion-button:not(.collapsed) {
        background: $actionColor;
        color: $foregroundColor;
        transition: color 0.13s ease-in;
        -webkit-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
        -moz-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
    }

    .accordion-button::after {
        background-image: url(../../assets/images/svgs/plus.svg) !important;

    }

    .accordion-button:not(.collapsed)::after {
        background-image: url(../../assets/images/svgs/minus.svg) !important;
        transform: rotate(-180deg);
    }
    .accordion-body {
        //background-color: $white;
        padding: calcRem(30px);
        -webkit-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
        -moz-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
        transition: box-shadow 1s !important;
    }
    .nav-tabs .nav-link {
        width: calcRem(120px);
        height: calcRem(55px);
        color: $primaryTextColor;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        font-family: $primaryFont;
    }
    .nav-item.button.nav-link {

    }
    //.nav-tabs .nav-link.active {
    //    color: $foregroundColor;
    //    border-color: $white $white $foregroundColor;
    //    background-color: $actionColor;
    //    border-bottom: solid 2px $foregroundColor;
    //}
    .fade {
        font-family: $primaryFont;
        margin-top: calcRem(30px);
    }
}
