@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

body {
  background-color:#e4efea !important;
}

.timeline__widget {

  overflow-x: hidden;
  padding: 20px;
  margin: 0 auto;
  max-width: 90%;
}
