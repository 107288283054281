@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

  .middle {
    height:100%;
    display:flex;
    flex-direction: column;
  }
  .dot{
    margin-top:calcRem(5px);
    margin-bottom:calcRem(5px);
  }
  .vl {
    border-left: calcRem(3px) solid $successColor;
    height: 100%;
    margin-left: calcRem(6px);
    margin-top: calcRem(3px);
    margin-bottom: calcRem(3px);
  }
  .before{
    background-color: $before;
    margin-right:calcRem(15px);
  }
  .after{
    background-color: $after;
    margin-right: calcRem(15px);
  }
.changes {
  margin-bottom: calcRem(15px);
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.button-toggle-timeline{
  display: flex;
  justify-content: space-around;
}

